<template>
  <div class="card">
    <!--  ADD NEW LANGUAGE  -->
    <h5 class='font-bold hr-line-dashed-bottom' style='margin-bottom: 1.4rem' >{{ t['108'] }}</h5>
    <div class='formgroup-inline'>
      <div class="field">
        <span class="p-float-label">
          <InputText id="codeInput" type="text" v-model='languageCodeValue'/>
          <label for="codeInput" class='font-bold'>{{ t['109'] }}</label>
        </span>
      </div>
      <div class="field">
        <span class="p-float-label">
          <InputText id="localeInput" type="text" v-model='languageLocaleValue'/>
          <label for="localeInput" class='font-bold'>{{ t['110'] }}</label>
        </span>
      </div>
      <div class="field">
        <span class="p-float-label">
          <InputText id="nameInput" type="text" v-model='languageNameValue'/>
          <label for="nameInput" class='font-bold'>{{ t['111'] }}</label>
        </span>
      </div>
      <Button :label="t['112']" icon='pi pi-save' :disabled='!(languageCodeValue && languageLocaleValue && languageNameValue)' @click='addNewLanguage'></Button>
    </div>
  </div>
  <div class="card">
    <!--  LANGUAGES DATATABLE  -->
    <h5 class='font-bold hr-line-dashed-bottom' style='margin-bottom: 1.4rem' >{{ t['113'] }}</h5>
    <DataTable :value="languageList" editMode="row" dataKey='lang' v-model:editingRows='editingRows' class="p-datatable-sm" :sortOrder='1' :rowClass="rowClass" :rowHover="true"
               @row-edit-init='onRowEditInit' @row-edit-cancel='onRowEditCancel' @row-edit-save='onRowEditSave' responsiveLayout='scroll' :loading='loading'>
      <template #empty>
        {{ t['125'] }}
      </template>
      <template #loading>
        {{ t['126'] }}
      </template>
      <Column field="lang" :header="t['109']">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.props.field]" :disabled='true'/>
        </template>
      </Column>
      <Column field="locale" :header="t['110']">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.props.field]"/>
        </template>
      </Column>
      <Column field="desc" :header="t['111']">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.props.field]"/>
        </template>
      </Column>
      <Column field="enabled" :header="t['117']">
        <template #editor="slotProps">
          <InputSwitch v-model="slotProps.data[slotProps.column.props.field]"/>
        </template>
        <template #body="slotProps">
          <InputSwitch v-model="slotProps.data[slotProps.column.props.field]" :disabled='true'/>
        </template>
      </Column>
      <Column :rowEditor="true" style="width: 10%;min-width:8rem" bodyStyle="text-align:center"></Column>
      <Column style="width:20%">
        <template #body="slotProps">
          <Button icon="pi pi-chevron-down" class="p-button-outlined p-button-primary mr-2" @click='openDictionary(slotProps.data)' :disabled='true'></Button>
          <Button icon="pi pi-globe" class="p-button-outlined p-button-primary mr-2" @click='setAsDefaultLanguage(slotProps.data)' :disabled='!slotProps.data.enabled'></Button>
          <Button icon="pi pi-clone" class="p-button-outlined p-button-warning mr-2" @click='duplicateLanguage(slotProps.data)'></Button>
          <Button icon="pi pi-trash" class="p-button-outlined p-button-danger mr-2" @click='deleteLanguageFunction(slotProps.data)' :disabled='slotProps.data.enabled'></Button>
        </template>
      </Column>
    </DataTable>
  </div>

  <!-- SET AS APP DEFAULT LANGUAGE DIALOG -->
  <Dialog v-model:visible="defaultDialogVisible" :style="{width: '450px'}" :header="t['127']" :modal="true" :closable="false" class="p-fluid">
    <p class="m-0">{{ t['128'].replace('{langCode} - {langDesc}', cloneLanguage.lang + ' - ' + cloneLanguage.desc) }}</p>
    <template #footer>
      <Button :label="t['86']" icon="pi pi-times" class="p-button-outlined mr-2 mb-2" @click="defaultDialogVisible=false"/>
      <Button :label="t['129']" icon="pi pi-check" class="p-button-outlined mr-2 mb-2" @click="updateDefaultLanguage" />
    </template>
  </Dialog>

  <!-- CLONE LANGUAGE DIALOG -->
  <Dialog :visible="cloneDialogVisible" :style="{width: '450px'}" :header="t['118']" :modal="true" :closable="false" class="p-fluid">
    <!-- <h5 class="font-bold hr-line-dashed-bottom">New language</h5> -->
    <Fieldset :legend="t['119']">
      <div class="field">
        <label for="name">{{ t['109'] }}</label>
        <InputText id="name" v-model="cloneLanguage.lang" required="true" />
      </div>
      <div class="field">
        <label for="locale">{{ t['110'] }}</label>
        <InputText id="locale" v-model="cloneLanguage.locale" required="true" />
      </div>
      <div class="field">
        <label for="desc">{{ t['111'] }}</label>
        <InputText id="desc" v-model="cloneLanguage.desc" required="true"  />
      </div>
    </Fieldset>
    <br>
    <p>{{ t['120'].replace('{langCode} - {langDesc}', cloneLanguage.lang + ' - ' + cloneLanguage.desc)  }}</p>

    <template #footer>
      <Button :label="t['86']" icon="pi pi-times" class="p-button-outlined mr-2 mb-2" @click="cloneDialogVisible=false"/>
      <Button :label="t['121']" icon="pi pi-check" class="p-button-outlined mr-2 mb-2" @click="cloneLanguageExecute" />
    </template>
  </Dialog>

  <!-- DELETE CONFIRMATION DIALOG -->
  <Dialog :header="t['92']" v-model:visible="deleteDialogVisible" :style="{width: '450px'}" :modal="true">
    <div class="confirmation-content">
      <div class="flash flash-warn flash-full">
        <p>{{ t['93'] }}</p>
      </div>
      <br>
      <p>{{ t['122'] }}</p>
      <p>{{ t['123'].replace('::DELETE_CONFIRMATION_STRING::', deleteLanguageConfirmationString) }}</p>
      <div class="field">
        <InputText id="deleteLang" type="text" style="width: 100%;" v-model="deleteLanguageConfirmationInput"/>
      </div>
      <Button :label="t['124']" class="p-button-outlined p-button-danger mr-2 mb-2" style="width: 100%;" @click="deleteLanguageExecute"
              :disabled="!enableDeleteLanguage"/>
    </div>
    <br>
  </Dialog>


</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { usePrimeVue } from 'primevue/config';
import axios from 'axios';

export default {
  name: 'Languages',
  setup() {
    const store = useStore()
    const primeVue = usePrimeVue()

    return {
      token: computed(() => store.state.bearerToken),
      t: primeVue.config.locale,
    }
  },
  data() {
    return {
      languageCodeValue: null,
      languageLocaleValue: null,
      languageNameValue: null,
      languageList: null,
      languageDefaultValue: null,
      loading: true,
      // datatable
      editingRows: [],
      // set as default dialog
      defaultDialogVisible: false,
      defaultLanguage: null,
      // clone language dialog
      cloneDialogVisible: false,
      cloneLanguage: null,
      cloneLanguageHeader: null,
      // delete language dialog
      deleteDialogVisible: false,
      deleteLanguage: null,
      deleteLanguageConfirmationString: null,
      deleteLanguageConfirmationInput: null,
    }
  },
  originalRows: null,
  created() {
    this.originalRows = {}
  },
  mounted() {
    this.getAllLanguages()
  },
  watch:{

  },
  computed:{
    enableDeleteLanguage: function() {
      return this.deleteLanguageConfirmationString === this.deleteLanguageConfirmationInput
    }
  },
  methods: {
    getAllLanguages() {
      axios
          .get( 'administration/language/list',  {
            headers: {
              Authorization: this.token
            }
          })
          .then( res => {
            if(res.status === 200 && res.data.rmsList[0].retCode === 0){
              this.languageList = res.data.retObj.languages
              this.languageDefaultValue = res.data.retObj.defAppLang
              this.cloneLanguage = this.languageList[0]
              this.deleteLanguage = this.languageList[0]
              this.loading = false
            }else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
            }
          })
          .catch(error => {
            console.log("There was an error loading languages list!")
            console.log(error)
          })
    },
    addNewLanguage() {
      axios
          .post('/administration/language/create', {
            'lang': this.languageCodeValue,
            'locale': this.languageLocaleValue,
            'desc': this.languageNameValue,
            'enabled': false
          }, {
            headers: {
              Authorization: this.token
            }
          })
          .then(res => {
            if(res.data.rmsList[0].retCode === 100) {
              this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
              this.languageCodeValue = null
              this.languageLocaleValue = null
              this.languageNameValue = null
              this.languageList = res.data.retObj.languages
            } else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
            }
          })
          .catch(error  => {
            console.log(error);
          })
    },
    saveLanguage(languageData) {
      axios
          .post('/administration/language/saveLanguage', {
            'lang': languageData.data.lang,
            'locale': languageData.data.locale,
            'desc': languageData.data.desc,
            'enabled': languageData.data.enabled
          }, {
            headers: {
              Authorization: this.token
            }
          })
          .then(res => {
            if(res.data.rmsList[0].retCode === 107) {
              this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
            } else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
            }
          })
          .catch(error  => {
            console.log("There was an error saving language!")
            console.log(error)
          })
    },
    openDictionary(languageData) {
      console.log(languageData)
    },
    setAsDefaultLanguage(languageData) {
      this.defaultLanguage = languageData
      this.defaultDialogVisible = true
    },
    updateDefaultLanguage() {
      this.loading = true
      axios
          .post('/administration/language/' + this.defaultLanguage.lang + '/setAsDefault', {}, {
            headers: {
              Authorization: this.token
            }
          })
          .then(res => {
            if(res.data.rmsList[0].retCode === 102) {
              // TODO: Success message needs to be translated
              this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
              this.getAllLanguages()
            } else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
            }
          })
          .catch(error  => {
            console.log("There was an error updating default language!")
            console.log(error)
          })
      this.defaultDialogVisible = false
    },
    duplicateLanguage(languageData) {
      this.cloneLanguage = JSON.parse(JSON.stringify(languageData))
      this.cloneLanguageHeader = languageData.lang
      this.cloneDialogVisible = true
    },
    cloneLanguageExecute() {
      this.loading = true
      axios
          .post('/administration/language/' + this.cloneLanguageHeader + '/clone', {
            'lang': this.cloneLanguage.lang,
            'locale': this.cloneLanguage.locale,
            'desc': this.cloneLanguage.desc,
            'enabled': false
          }, {
            headers: {
              Authorization: this.token
            }
          })
          .then(res => {
            if(res.data.rmsList[0].retCode === 100) {
              this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
              this.languageList = res.data.retObj.languages
              this.cloneDialogVisible = false
              this.cloneLanguage = this.languageList[0]
              this.cloneLanguageHeader = null
              this.loading = false
            } else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
            }
          })
          .catch(error  => {
            console.log(error)
          })
    },
    deleteLanguageFunction(languageData) {
      this.deleteLanguage = JSON.parse(JSON.stringify(languageData))
      this.deleteDialogVisible = true
      this.deleteLanguageConfirmationString = this.deleteLanguage.lang + '-' + this.deleteLanguage.desc
    },
    deleteLanguageExecute() {
      this.loading = true;
      axios
          .post( 'administration/language/' + this.deleteLanguage.lang + '/delete',  {}, {
            headers: {
              Authorization: this.token
            }
          })
          .then( res => {
            if(res.status === 200 && res.data.rmsList[0].retCode === 106) {
              this.$toast.add({severity:'success', summary: 'Successful', detail:res.data.rmsList[0].retMsg, life: 3000})
              this.languageList = res.data.retObj.languages
              this.deleteDialogVisible = false
              this.deleteLanguage = this.languageList[0]
              this.deleteLanguageConfirmationString = null
              this.deleteLanguageConfirmationInput = null
              this.loading = false
            }else {
              this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000});
              this.deleteLanguageConfirmationInput = null
            }
          })
          .catch(error => {
            console.log("There was an error loading languages list!")
            console.log(error)
            this.deleteLanguageConfirmationInput = null
          })
    },
    onRowEditInit(event) {
      this.originalRows[event.index] = {...this.languageList[event.index]};
    },
    onRowEditCancel(event) {
      this.languageList[event.index] = this.originalRows[event.index];
    },
    onRowEditSave(event) {
      this.saveLanguage(event)
    },
    rowClass(data) {
      return data.lang === this.languageDefaultValue ? 'row-default': null;
    },
  }
};
</script>

<style scoped lang='scss'>

.hr-line-dashed-bottom {
  border-bottom: 1px dashed lightgray;
}

.flash {
  position: relative;
  padding: 20px 16px;
  padding-top: 20px;
  padding-right: 16px;
  padding-bottom: 20px;
  padding-left: 16px;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
}

.flash-warn {
  color: #24292E;
  background-image: linear-gradient(to right, rgba(255,0,0,0.15), rgba(255,0,0,0.15));
  border-color: #D32F2F;
}

.flash-full {
  border-width: 1px 0;
  border-radius: 0;
}

/*TODO: check!*/
/*/deep/ .row-default {
  background-color: rgba(#4baf50, 0.2) !important;
}*/
</style>